import {useTranslation} from "react-i18next";

const About = () => {
    const {t} = useTranslation('common')

    return (
        <article className="about">

            <header>
                <h2 className="h2 article-title">{t('about')}</h2>
            </header>

            <section className="about-text">
                <p>
                    Passionné d'informatique depuis désormais 7 ans, je suis actuellement un cycle de formation
                    en alternance chez Protech SAS.
                </p>

                <p>
                    Mon parcours commence par le développement Java, dans le but de concevoir des plugins minecraft. Petit à petit,
                    les termes base de données, redis, cache me sont devenus de plus en plus familiers.
                </p>

                <p>
                    Deux ans plus tard, j'ai mis les pieds dans le monde du web avec un forum et un site d'administration / de supervision
                    d'instances.
                </p>

                <p>
                    Cette expérience d'interconnexion de serveurs et d'interfaces web m'a permis de découvrir l'univers de l'administration système et réseau,
                    dans lequel j'évolue depuis bientôt 4 ans maintenant.
                </p>
            </section>

        </article>
    )
}

export default About