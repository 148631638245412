import {useTranslation} from "react-i18next";

const Portfolio = () => {
    const {t} = useTranslation('common')

    return (
        <article className="portfolio">
            <header>
                <h2 className="h2 article-title">{t('synthese')}</h2>
            </header>

            <section className="synthese">

                <img width="800px" src="img/tableau_synthese.png" alt="Tableau de synthèse"/>

            </section>
        </article>
    )
}

export default Portfolio