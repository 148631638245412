import {useTranslation} from "react-i18next";

const projects = {
    "Administration router pfSense": "Installation, configuration de base, mise en place de règles de pare-feu, DHCP, DNS, VPN (OpenVPN, WireGuard), site to site, routage (BGP), VLAN, IDS (snort / suricata).",
    "Sauvegardes automatisées": "Sauvegardes avec proxmox backup server et scripts bash avec endpoint S3 ou NFS.",
    "Apprentissage autodidacte": "Apprentissage de linux, de solutions de monitoring et d'alerting avec grafana, influxdb, prometheus, elastic, netdata ; apprentissage de docker puis swarm et kubernetes ; pfSense, proxmox...",
    "Solutions de monitoring": "Monitoring d'infrastructures à l'aide de grafana, influxdb, prometheus, elastic, netdata, loki. L'objectif étant de centraliser la collecte de métriques et de logs pour surveiller l'ensemble du parc.",
    "Infrastructure de serveurs de jeu à la demande": "Sur les projets Wyntale et Unitale avec une infrastructure basée sur kubernetes, développement d'un logiciel 'master' en kotlin, base sur hazelcast pour coordonner les différents services et gérer les données joueurs.",
    "Création d'un logiciel de ticketing": "Création de Supporter, un logiciel de ticketing permettant de centraliser, traiter et répondre aux problèmes de nos clients. Utilisation de Laravel avec un déploiement en failover.",
    "Mise en place d'infrastructure de virtualisation multi sites": "Basée sur proxmox, cette infrastructure permettra d'assurer la haute disponibilité des services. Les différents sites disposent de plusieurs serveurs de virtualisation sous proxmox et le réseau est géré par pfSense (avec des VPN WireGuard pour le site to site).",
    "Infrastructure HA": "Cette infrastructure, utilisant un routage BGP, un cluster k3s sur plusieurs zones, du stockage s3 (seaweed) et redondé (longhorn), cluster galera, PostgreSQL FO et des microservices, permet d'assurer la haute disponibilité (ou du moins le failover) de nombreux services.",
    "Support client": "Relation client, interventions sur site (installation / changement / dépannage de matériel) et apprentissage d'un logiciel métier dans le but d'assurer son support.",
}

const Projects = () => {
    const {t} = useTranslation('common')

    return (
        <article className="contact">

            <header>
                <h2 className="h2 article-title">{t('projects')}</h2>
            </header>
            
            <section className="timeline">

                {Object.entries(projects).map(([key, value]) => {
                    return <ol className="timeline-list">
                        <li className="timeline-item">
                            <h4 className="h4 timeline-item-title">{key}</h4>

                            <p className="timeline-text">
                                {value}
                            </p>
                        </li>
                    </ol>
                })}
                
            </section>

        </article>
        )
}

export default Projects