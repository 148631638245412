import './App.css';
import {useTranslation} from "react-i18next";
import {Routes, Route, NavLink} from "react-router-dom";
import Resume from "./pages/Resume";
import About from "./pages/About";
import Synthese from "./pages/Synthese";
import TechDoc from "./pages/TechDoc";
import Projects from "./pages/Projects";

function App() {
    const {t} = useTranslation('common');

    return (
        <main>
            <aside className="sidebar">
                <div className="sidebar-info">

                    <div className="info-content">
                        <h1 className="name" title="Loïc Frémaux">Loïc Frémaux</h1>

                        <p className="title">{t('system_administrator')}</p>
                        <p className="title">{t('network_administrator')}</p>
                        <p className="title">{t('developer')}</p>
                    </div>
                </div>

                <div className="sidebar-info_more">
                    <div className="separator"></div>
                    <ul className="contacts-list">
                        <li className="contact-item">
                            <div className="icon-box">
                                <ion-icon name="mail-outline"></ion-icon>
                                <i class="fi fi-rr-envelope"></i>
                            </div>

                            <div className="contact-info">
                                <p className="contact-title">{t('email')}</p>
                                <a href="mailto:contact@lfremaux.fr" className="contact-link">contact@lfremaux.fr</a>
                            </div>
                        </li>

                        <li className="contact-item">
                            <div className="icon-box">
                                <ion-icon name="phone-portrait-outline"></ion-icon>
                                <i class="fi fi-rr-phone-call"></i>
                            </div>
                            <div className="contact-info">
                                <p className="contact-title">{t('phone')}</p>
                                <a href="tel:+33651715310" className="contact-link">+33 6 51 71 53 10</a>
                            </div>
                        </li>

                        <li className="contact-item">
                            <div className="icon-box">
                                <ion-icon name="calendar-outline"></ion-icon>
                                <i class="fi fi-rr-gift-card"></i>
                            </div>

                            <div className="contact-info">
                                <p className="contact-title">{t('birthsday')}</p>
                                <time dateTime="2003-02-20">20 février 2003</time>
                            </div>
                        </li>

                        <li className="contact-item">
                            <div className="icon-box">
                                <ion-icon name="location-outline"></ion-icon>
                                <i class="fi fi-rr-map"></i>
                            </div>

                            <div className="contact-info">
                                <p className="contact-title">{t('address')}</p>
                                <address>Paris, France</address>
                            </div>
                        </li>
                    </ul>

                    <div className="separator"></div>

                    <ul className="social-list">
                        <li className="social-item">
                            <a href="#" className="social-link">
                                <ion-icon name="logo-facebook"></ion-icon>
                            </a>
                        </li>

                        <li className="social-item">
                            <a href="#" className="social-link">
                                <ion-icon name="logo-twitter"></ion-icon>
                            </a>
                        </li>

                        <li className="social-item">
                            <a href="#" className="social-link">
                                <ion-icon name="logo-instagram"></ion-icon>
                            </a>
                        </li>
                    </ul>
                </div>
            </aside>

            <div className="main-content">
                <nav className="navbar">
                    <ul className="navbar-list">
                        <li className="navbar-item">
                            <NavLink className="navbar-link" to={"/"} data-nav-link>{t('about')}</NavLink>
                        </li>

                        <li className="navbar-item">
                            <NavLink className="navbar-link" to={"/resume"} data-nav-link>{t('resume')}</NavLink>
                        </li>

                        <li className="navbar-item">
                            <NavLink className="navbar-link" to={"/projects"} data-nav-link>{t('projects')}</NavLink>
                        </li>

                        <li className="navbar-item">
                            <NavLink className="navbar-link" to={"/tech-doc"} data-nav-link>{t('tech-doc')}</NavLink>
                        </li>

                        <li className="navbar-item">
                            <NavLink className="navbar-link" to={"/synthese"} data-nav-link>{t('synthese')}</NavLink>
                        </li>
                    </ul>
                </nav>

                <Routes>
                    <Route path="/" element={<About/>}></Route>
                    <Route path="/resume" element={<Resume/>}></Route>
                    <Route path="/projects" element={<Projects/>}></Route>
                    <Route path="/synthese" element={<Synthese/>}></Route>
                    <Route path="/tech-doc" element={<TechDoc/>}></Route>
                </Routes>
            </div>

        </main>
    );
}

export default App;
