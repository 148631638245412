import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import i18next from "i18next";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";

import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";
import {I18nextProvider} from "react-i18next";

i18next.init({
    interpolation: {escapeValue: false},
    lng: 'fr',
    resources: {
        fr: {
            common: common_fr
        },
        en: {
            common: common_en
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18next}>
            <App/>
        </I18nextProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
