import {useTranslation} from "react-i18next";

const TechDoc = () => {
    const {t} = useTranslation('common')

    return (
        <article className="contact">

            <header>
                <h2 className="h2 article-title">{t('tech-doc')}</h2>
            </header>

            <section>
                <h3 className="h3">Kubernetes</h3>

                <p className="timeline-text">
                    Kubernetes est l'une des solutions d'orchestration de conteneurs les plus populaires de nos jours, mais qu'est ce qu'on conteneur ?
                </p>

                <br/>

                <h4 className="h4">Les conteneurs</h4>

                <p className="timeline-text">
                    Il faut imaginer les conteneurs comme une sorte de boite, un environnement dans lequel notre programme va s'exécuter.
                    Il y a plusieurs avantages à cela. Tout d'abord, l'environnement d'exécution est toujours le même, quel que soit le type
                    de système sur lequel le conteneur s'exécute. C'est lui qui va créer cette couche d'abstraction permettant d'éviter l'erreur
                    classique du numéro de version incorrect pour la dépendance numéro 347.
                </p>

                <br/>

                <h4 className="h4">L'orchestration</h4>

                <p className="timeline-text">
                    C'est bien pratique de pouvoir déployer un conteneur, mais on se retrouve souvent à devoir en administrer des centaines.
                    C'est là qu'intervient kubernetes. Ce dernier permet de définir des règles et des politiques de déploiement très précises
                    et ainsi de répartir la charge sur de nombreuses machines ou instances cloud. En effet, kube s'intègre très bien aux
                    solutions de cloud comme AWS et permet d'ajouter ou de retirer de façon automatique des instances en fonction de la charge totale.
                </p>

                <br/>

                <h4 className="h4">Sources</h4>

                <p className="timeline-text">
                    Mes principales sources sont les recherches internet, dès qu'un sujet pique ma curiosité, je tape son nom sur Google
                    et consulte plusieurs articles le concernant.
                    Je consulte régulièrement les patch / release notes des logiciels / dépendances que j'utilise souvent et reçois
                    des alertes par mail lorsque des mises à jour sont publiées.
                    J'écoute aussi beaucoup de podcasts sur spotify, Underscore dans le domaine de la tech par exemple.
                </p>
            </section>

        </article>
    )
}

export default TechDoc