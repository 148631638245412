import {useTranslation} from "react-i18next";

const Resume = () => {
    const {t} = useTranslation('common')

    return (
        <article className="resume">
            <header>
                <h2 className="h2 article-title">{t('resume')}</h2>
            </header>

            <section className="timeline">
                <div className="title-wrapper">
                    <div className="icon-box">
                        <i className="fi fi-sr-graduation-cap"></i>
                    </div>

                    <h3 className="h3">{t('education')}</h3>
                </div>

                <ol className="timeline-list">
                    <li className="timeline-item">
                        <h4 className="h4 timeline-item-title">BTS SIO H3 Hitema Issy / Alternance Protech SAS</h4>
                        <span>2021 — 2023</span>
                        <p className="timeline-text">
                            BTS Service Informatique aux Organisations en alternance chez Protech SAS. Après bientôt 2 ans d'apprentissage,
                            j'ai pu découvrir le support informatique, les interventions sur site, la maintenance d'un logiciel métier.
                            J'ai également réalisé plusieurs interfaces web de gestion, logiciels et APIs et mis en place
                            des infrastructures systèmes et réseau entre plusieurs sites, permettant une haute disponibilité des services.
                        </p>
                    </li>

                    <li className="timeline-item">
                        <h4 className="h4 timeline-item-title">Lycée Edouard Branly</h4>
                        <span>2019 — 2021</span>
                        <p className="timeline-text">
                            Première et Terminale général spécialités NSI, SI, Mathématiques, options Maths complémentaires et SI en anglais.
                            Bac mention bien.
                        </p>
                    </li>
                </ol>

            </section>

            <section className="timeline">
                <div className="title-wrapper">
                    <div className="icon-box">
                        <i className="fi fi-rr-briefcase"></i>
                    </div>

                    <h3 className="h3">{t('experiences')}</h3>
                </div>

                <ol className="timeline-list">
                    <li className="timeline-item">
                        <h4 className="h4 timeline-item-title">Solution monitoring IoT</h4>
                        <span>2022</span>

                        <p className="timeline-text">
                            Mise en place d'une solution sur-mesure de surveillance de températures
                            avec des modules fabriquées à partir d'ESP32 et une stack telegraf, influxdb, grafana.
                        </p>
                    </li>
                </ol>

                <ol className="timeline-list">
                    <li className="timeline-item">
                        <h4 className="h4 timeline-item-title">Maintenance informatique PME</h4>
                        <span>2020 — Present</span>

                        <p className="timeline-text">
                            Montage de serveur, installation réseau, mise en place Windows Server, AD, partages réseau,
                            configuration des périphériques, mise en place stations, IPBX asterisk avec connexion Orange Business Services, monitoring, sauvegardes.
                        </p>
                    </li>
                </ol>

                <ol className="timeline-list">
                    <li className="timeline-item">
                        <h4 className="h4 timeline-item-title">Projets minecraft Wyntale et Unitale</h4>
                        <span>2019 — Present</span>

                        <p className="timeline-text">
                            Administrateur système et réseau, développeur back-end sur les projets Minecraft Wyntale et Unitale.
                            Développement de programmes d'orchestration de serveurs à la demande en Java et Kotlin, cluster Docker swarm et Kubernetes.
                            Interface de gestion laravel ; monitoring prometheus, grafana, loki, netdata, registry docker harbor, intégration continue,
                            sauvegardes
                        </p>
                    </li>
                </ol>

                <ol className="timeline-list">
                    <li className="timeline-item">
                        <h4 className="h4 timeline-item-title">Apprentissage autodidacte</h4>
                        <span>2016 — Present</span>

                        <p className="timeline-text">
                            Apprentissage de Java en 2016, suivi 2 ans après de l'univers web (html, css, php, js).
                            Premières expérience avec l'administration système en 2017, découverte de linux, des bases du réseau.
                            Découverte de docker en 2019 puis de kubernetes en 2021. Mise en place d'infrastructures haute disponibilité
                            à partir de 2022. Premier logiciel en .NET (backup incrémentielle) en 2023.
                        </p>
                    </li>
                </ol>
            </section>

            <section className="skill">
                <h3 className="h3 skills-title">{t('skills')}</h3>

                <div className="skills-block">
                    <div>
                        <h4 className="h4 skill-title">Développement</h4>
                        <ul className="skills-list">
                            <li>Java</li>
                            <li>Kotlin</li>
                            <li>HTML</li>
                            <li>CSS, SASS</li>
                            <li>Php (Laravel)</li>
                            <li>Javascript (React)</li>
                            <li>SQL</li>
                            <li>.NET</li>
                            <li>Bash</li>
                            <li>Git, Github, Gitlab</li>
                        </ul>
                    </div>

                    <div>
                    <h4 className="h4 skill-title">Système</h4>
                    <ul className="skills-list">
                        <li>Linux (Debian, Ubuntu, Rocky, TrueNAS)</li>
                        <li>Windows (AD, GPO, HyperV)</li>
                        <li>Proxmox</li>
                        <li>Stockage (MinIO, DRBD, Ceph, Seaweed)</li>
                        <li>Reverse Proxy (HAProxy, Nginx, Traefik)</li>
                        <li>Clusters (Corosync, Pacemaker, Keepalived)</li>
                        <li>Monitoring (Grafana, InfluxDB, Loki, Prometheus)</li>
                        <li>Conteneurs (Docker, Kubernetes)</li>
                        <li>Bases de données (MariaDB, Galera)</li>
                        <li>Serveur mail (mailcow)</li>
                        <li>Intégration continue (Jenkins, Gitlab)</li>
                        <li>Sauvegardes (PBS, scripts)</li>
                    </ul>
                    </div>

                    <div>
                        <h4 className="h4 skill-title">Réseau</h4>
                        <ul className="skills-list">
                            <li>Routage (statique, BGP)</li>
                            <li>NAT</li>
                            <li>VLAN</li>
                            <li>DNS</li>
                            <li>DHCP</li>
                            <li>GRE</li>
                            <li>VPN (OpenVPN, Wireguard, IPSec)</li>
                            <li>pfSense</li>
                            <li>Pare-feu (pfSense, iptables, ufw)</li>
                            <li>Virtual IPs</li>
                        </ul>
                    </div>
                </div>
            </section>

        </article>
    )
}

export default Resume;